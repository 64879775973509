<template>
  <v-dialog v-model="dialog" max-width="600">
    <v-card :loading="loading">
      <v-card-title>
        تیکت
        <v-spacer />

        <v-btn outlined color="blue" @click="dialog = false">
          بازگشت
        </v-btn>
      </v-card-title>

      <v-card-text>
        <div class="my-2">
          <div class="grey--text">عنوان تیکت</div>
          <v-text-field
            background-color="grey lighten-2"
            hide-details="auto"
            solo
            flat
            class="rounded-lg"
            v-model="title"
          />
        </div>
        <div class="my-2">
          <div class="grey--text">متن تیکت</div>
          <v-textarea
            background-color="grey lighten-2"
            hide-details="auto"
            solo
            flat
            class="rounded-lg"
            v-model="desc"
          />
        </div>
        <div class="mt-4 d-flex">
          <v-spacer />
          <v-btn
            class="mx-1"
            dark
            large
            small
            color="primary"
            :loading="loading"
            @click="sendTicket"
          >
            ثبت
          </v-btn>
        </div>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import { getErrorText } from "@/constants/GlobalConstants";
import MyAxios from "@/constants/MyAxios";
export default {
  data() {
    return {
      dialog: false,
      loading: false,
      title: "",
      desc: "",
    };
  },
  methods: {
    showModal() {
      this.dialog = true;
    },
    sendTicket() {
      if (this.title <= 0 || this.desc <= 0) {
        this.$root.$emit("toast", {
          text: "پاسخ را به درستی وارد نمایید",
        });
        return;
      }

      if (!this.loading) this.loading = true;
      let formData = new FormData();

      formData.append("user_id", this.$store.state.userData.id);
      formData.append("title", this.title);
      formData.append("desc", this.desc);

      MyAxios.post("/tickets/create", formData)
        .then((response) => {
          this.title = "";
          this.desc = "";
          this.loading = false;
          this.dialog = false;
          this.$root.$emit("toast", {
            text: "تیکت شما با موفقیت ثبت شد.",
            type: "success",
          });
          this.$emit("GetList");
          console.log(response);
        })
        .catch((error) => {
          this.loading = false;
          this.$root.$emit("toast", {
            text: getErrorText(error),
          });
          console.log(error, error.response);
        });
    },
  },
};
</script>

<style></style>
