<template>
  <v-container class="d-flex justify-center" fluid>
    <v-row class="mt-n13" no-gutters>
      <products-modal ref="detailsModal" :selectedData="selectedData" />
      <v-col cols="12">
        <v-card class="moholand-shadow rounded-lg">
          <v-card-title>
            فایل های من
          </v-card-title>

          <div class="d-flex flex-wrap pa-4">
            <v-btn
              dark
              large
              class="rounded-lg mx-2 moholand-shadow my-auto"
              color="orange darken-2"
              to="/user/files/create"
            >
              افزودن فایل جدید
            </v-btn>
            <!-- <v-btn
              dark
              large
              class="rounded-lg mx-2 moholand-shadow my-auto"
              color="red"
              @click="$refs.ticketNewModalRef.showModal()"
            >
              حذف
            </v-btn> -->
            <search-bar @onSubmit="onSearchSubmit" />
          </div>

          <MyDataTable
            :headers="headers"
            :items="items"
            :loading="loading"
            item-key="id"
            :pagesLength="pagesLength"
            @onPageChanged="GetList"
            @onEditClick="onEditClick"
            @onDeleteClick="deleteItem"
          >
            <template #middleButtonTag="{ row }">
              <v-btn
                class="mx-1"
                dark
                small
                color="success"
                @click="onDetailsClick(row)"
              >
                مشاهده
              </v-btn>
            </template>
            <template #contentTag="{ row }">
              <td>
                <div class="d-flex align-center">
                  <v-img
                    v-if="row.media"
                    max-height="70"
                    max-width="70"
                    class="rounded-xl ma-2"
                    :src="row.media.url"
                    contain
                  />
                  {{ row.name }}
                </div>
              </td>
              <td class="text-center">
                <v-chip
                  v-if="row.status == 0"
                  color="yellow darken-3"
                  dark
                  class="rounded"
                  small
                >
                  در حال بررسی
                </v-chip>
                <v-chip
                  v-else-if="row.status == 1"
                  color="success"
                  dark
                  class="rounded"
                  small
                >
                  تایید شده
                </v-chip>
                <v-tooltip v-else top color="black">
                  <template v-slot:activator="{ on, attrs }">
                    <v-chip
                      color="red"
                      dark
                      class="rounded"
                      small
                      v-bind="attrs"
                      v-on="on"
                    >
                      <v-icon small class="ml-2">
                        mdi-information-outline
                      </v-icon>
                      رد شده
                    </v-chip>
                  </template>
                  <span>{{ row.status_desc }}</span>
                </v-tooltip>
              </td>
            </template>
          </MyDataTable>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import MyDataTable from "@/components/MyDataTable.vue";
import TicketContentModal from "@/components/Panel/TicketContentModal.vue";
import TicketNewModal from "@/components/Panel/TicketNewModal.vue";
import SearchBar from "@/components/SearchBar.vue";
import MyAxios from "@/constants/MyAxios";
import ProductsModal from "../../../components/Panel/ProductsModal.vue";
export default {
  components: {
    SearchBar,
    MyDataTable,
    TicketContentModal,
    TicketNewModal,
    ProductsModal,
  },
  created() {
    this.GetList();
  },
  methods: {
    GetList() {
      if (!this.loading) this.loading = true;

      MyAxios.get("/products/index", {
        params: {
          page: this.$route.query?.page || 1,
          ...this.pathParams,
        },
      })
        .then((response) => {
          this.loading = false;
          this.items = response.data.data;
          this.pagesLength = response.data.last_page;
        })
        .catch((error) => {
          this.loading = false;
        });
    },
    onSearchSubmit(name) {
      this.conditions = { name: name.length > 0 ? name : undefined };
      this.GetList();
    },
    onEditClick(item) {
      this.$router.push({
        path: "/user/files/edit/" + item.slug,
      });
    },
    onDetailsClick(item) {
      this.selectedData = item;
      this.$refs.detailsModal.dialog = true;
    },
    deleteItem(item) {
      MyAxios.delete(`/products/${item.slug}/delete`)
        .then((response) => {
          console.log("delete", response);
          this.$root.$emit("toast", {
            text: "آیتم با موفقیت حذف شد.",
            type: "success",
          });
          this.GetList();
        })
        .catch((error) => {
          console.log("laws", error.response);
        });
    },
    updateItems(items) {
      this.items = items;
    },
  },
  data() {
    return {
      loading: true,
      items: [],
      selectedData: {},
      page: 1,
      pagesLength: 1,
      path: "/products/index",
      pathParams: {
        conditions: {
          author_id: this.$store.state?.userData?.id || 0,
        },
      },
      conditions: {},
      headers: [
        {
          text: "اطلاعات فایل",
          align: "start",
          value: "title",
          groupable: false,
          sortable: false,
        },
        {
          text: "وضعیت فایل",
          align: "start",
          value: "file_name",
          groupable: false,
          sortable: false,
        },
        {
          text: "عملیات",
          value: "actions",
          groupable: false,
          sortable: false,
        },
      ],
    };
  },
};
</script>

<style></style>
